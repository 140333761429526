import React, { useRef, useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from 'layouts/zh'
import Seo from 'components/seo'
import Banner from 'components/Solutions/Banner'
import VideoPlayer from 'components/VideoPlayer'
import Section from 'components/SolutionComponents/Section'
import SolutionsSwiper from 'components/Solutions/SolutionsSwiper'
import CaseShare from 'components/SolutionComponents/CaseShare'
import SigningPlate from 'components/Solutions/SigningPlate'
import PageBottomCard from 'components/PageBottomCardNew'
import ScenarioCard from 'components/Solutions/ScenarioCard'
import lottieData1 from 'i18n/adtrack/data1.json' // lottie1 路径动画1
import lottieData2 from 'i18n/adtrack/data2.json' // lottie2 路径动画2
import lottieData3 from 'i18n/adtrack/data3.json' // lottie2 路径动画3
// import lottieDataOne from 'i18n/customerjourney/datatest.json' // 业务场景第一部分动画数据
import lottieDataOne from 'i18n/adtrack/dataa.json' // 业务场景第一部分动画数据
import lottieDataTwo from 'i18n/adtrack/datab.json' // 业务场景第二部分动画数据
import lottieDataThree from 'i18n/adtrack/datac.json' // 业务场景第三部分动画数据
import lottieDataFour from 'i18n/adtrack/datad.json' // 业务场景第四部分动画数据
import Lottie, { AnimationItem } from 'lottie-web'
import { useScroll } from 'ahooks'
import { isPc } from 'utils/utils'
import * as styles from './index.module.less'
import classnames from 'classnames'
import useIsWindowWidthSmaller from '../../../hooks/useWindowWidth'
import 'swiper/swiper-bundle.css'

import CloseIcon from 'assets/images/topic/ds2021/icon-close.svg'

const Adtrack: React.FC = (props) => {
  return (
    <Layout {...props}>
      <AdtrackState />
    </Layout>
  )
}
export const AdtrackState: React.FC = () => {
  const { adtrackYaml } = useStaticQuery(graphql`
    query {
      adtrackYaml {
        title
        desc
        keywords
        saTitle
        banner {
          title
          desc
          button_group {
            left_button_text
            left_button_link
            right_button_text
            right_button_link
          }
          right_img {
            publicURL
          }
          right_link
          right_img_alt
          banner_bg {
            publicURL
          }
          banner_bg_mb {
            publicURL
          }
        }
        scenario {
          title
          mask_title
          dataSource {
            reverse
            left_card_static_mg {
              publicURL
            }
            right_icon {
              publicURL
            }
            right_title
            right_title_mb
            right_desc
            right_desc_mb
            right_content
            right_content_mb
            menu {
              icon_path
              text
            }
            btn_text_one
            btn_link_one
            btn_text_two
            btn_link_two
            img_alt
          }
        }
        customer_story {
          hasTab
          title
          mask_title
          img_bg {
            publicURL
          }
          img_bg_mb {
            publicURL
          }
          data_source {
            img_url {
              publicURL
            }
            title
            desc
            btn_text
            btn_link
          }
        }
        insights {
          title
          mask_title
          section_one {
            title
            desc
            btn_link
            btn_text
            content_img {
              publicURL
            }
          }
        }
        more {
          title
          mask_title
          card_data {
            left {
              title
              bg {
                publicURL
              }
              desc
              btn_text
              btn_link
            }
            right {
              title
              bg {
                publicURL
              }
              desc
              btn_text
              btn_link
            }
          }
        }
      }
    }
  `)

  // 判断是否被广告插件屏蔽元素
  const [adBlocked, setAdBlocked] = useState(false)

  const curVideo = useRef<any>(null) // 视频播放组件
  const svgWarpInstance1 = useRef<AnimationItem>(null) // lottie1
  const svgWarpInstance2 = useRef<AnimationItem>(null) // lottie2
  const svgWarpInstance3 = useRef<AnimationItem>(null) // lottie3
  const ScenarioCardOne = useRef<any>(null) // 业务场景第一部分
  const ScenarioCardTwo = useRef<any>(null) // 业务场景第二部分
  const ScenarioCardThree = useRef<any>(null) // 业务场景第三部分
  const ScenarioCardFour = useRef<any>(null) // 业务场景第四部分
  const scroll = useScroll(globalThis.document, (val) => val.top <= 2100) //滚动条高度 小于 2100 监控
  // const [isPcFlag, setIsPcFlag] = useState(true) // 是否pc端
  const lottie1Warp = useRef(null) // lottie1
  const lottie2Warp = useRef(null) // lottie2
  const lottie3Warp = useRef(null) // lottie3
  const isMb = useIsWindowWidthSmaller() //判断是否是移动端
  const { banner, scenario, customer_story, insights, more } = adtrackYaml
  const bannerBackgroundStyle = isMb
    ? {
        backgroundImage: `url(${banner?.banner_bg_mb?.publicURL || banner?.banner_bg_mb})`,
        backgroundSize: '100%',
      }
    : {
        backgroundImage: `url(${banner?.banner_bg?.publicURL || banner?.banner_bg})`,
        backgroundSize: '100% 100%',
      }
  const customerStyle = isMb
    ? {
        backgroundImage: `url(${customer_story?.img_bg_mb?.publicURL || customer_story?.img_bg_mb})`,
        backgroundSize: '100%',
      }
    : {
        backgroundImage: `url(${customer_story?.img_bg?.publicURL || customer_story?.img_bg})`,
        backgroundSize: '100% 100%',
      }

  useEffect(() => {
    if (lottie1Warp?.current && lottie2Warp?.current) {
      svgWarpInstance1.current = Lottie.loadAnimation({
        container: lottie1Warp.current,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: lottieData1,
      })
      svgWarpInstance2.current = Lottie.loadAnimation({
        container: lottie2Warp.current,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: lottieData2,
      })
      svgWarpInstance3.current = Lottie.loadAnimation({
        container: lottie3Warp.current,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: lottieData3,
      })
    }
    // !isPc() && setIsPcFlag(false)
  }, [])
  useEffect(() => {
    if (scroll?.top && svgWarpInstance1.current) {
      if (scroll?.top < 600) {
        // 滚动条小于600 不加载动画
        svgWarpInstance1.current.goToAndStop(0, true)
        svgWarpInstance2!.current!.goToAndStop(0, true)
        svgWarpInstance3!.current!.goToAndStop(0, true)
      } else if (scroll?.top >= 600 && scroll?.top < 962) {
        const target1Frames = ((scroll?.top - 600) / 362) * svgWarpInstance1.current?.totalFrames //目标帧
        // 600-962间加载动画
        target1Frames <= svgWarpInstance1.current?.totalFrames - 1 &&
          svgWarpInstance1.current.goToAndStop(target1Frames, true) //跳转至目标帧
        svgWarpInstance2!.current!.goToAndStop(0, true)
        svgWarpInstance3!.current!.goToAndStop(0, true)
      } else if (scroll?.top >= 1160 && scroll?.top < 1680) {
        svgWarpInstance1.current.goToAndStop(svgWarpInstance1.current?.totalFrames - 1, true)
        const target2Frames = ((scroll?.top - 1160) / 520) * svgWarpInstance1.current?.totalFrames //目标帧
        // 1160-1680间加载动画
        target2Frames <= svgWarpInstance1.current?.totalFrames - 1 &&
          svgWarpInstance2!.current!.goToAndStop(target2Frames, true) //跳转至目标帧
        svgWarpInstance3!.current!.goToAndStop(0, true)
      } else if (scroll!.top >= 1780 && scroll!.top < 2080) {
        svgWarpInstance1.current.goToAndStop(svgWarpInstance1.current?.totalFrames - 1, true)
        svgWarpInstance2!.current!.goToAndStop(svgWarpInstance2!.current!.totalFrames - 1, true)
        const target3Frames = ((scroll?.top - 1780) / 300) * svgWarpInstance1.current?.totalFrames //目标帧
        svgWarpInstance3!.current!.goToAndStop(target3Frames, true)
      } else if (scroll!.top >= 2080) {
        svgWarpInstance1.current.goToAndStop(svgWarpInstance1.current?.totalFrames - 1, true)
        svgWarpInstance2!.current!.goToAndStop(svgWarpInstance2!.current!.totalFrames - 1, true)
        svgWarpInstance3!.current!.goToAndStop(svgWarpInstance3!.current!.totalFrames - 1, true)
      }
    }
    if (ScenarioCardOne!.current?.getBoundingClientRect()?.top <= 460) {
      // 业务场景第一部分动态图片距离视口顶部小于 460xp 开始播放至最后一帧
      ScenarioCardOne!.current?.play()
    } else {
      // 业务场景动态图片距离视口顶部大于 356xp 返回第一帧并停止
      ScenarioCardOne!.current?.init()
    }
    if (ScenarioCardTwo!.current?.getBoundingClientRect()?.top <= 510) {
      // 业务场景第二部分动态图片距离视口顶部小于 510xp 开始播放至最后一帧
      ScenarioCardTwo!.current?.play()
    } else {
      // 业务场景第二部分动态图片距离视口顶部大于510xp返回第一帧并停止
      ScenarioCardTwo!.current?.init()
    }
    if (ScenarioCardThree!.current?.getBoundingClientRect()?.top <= 420) {
      // 业务场景第三部分动态图片距离视口顶部小于 420xp 开始播放至最后一帧
      ScenarioCardThree!.current?.play()
    } else {
      // 业务场景第三部分动态图片距离视口顶部大于420返回第一帧并停止
      ScenarioCardThree!.current?.init()
    }
    if (ScenarioCardFour!.current?.getBoundingClientRect()?.top <= 600) {
      // 业务场景第四部分动态图片距离视口顶部小于 600xp 开始播放至最后一帧
      ScenarioCardFour!.current?.play()
    } else {
      // 业务场景第四部分动态图片距离视口顶部大于600xp返回第一帧并停止
      ScenarioCardFour!.current?.init()
    }
    // console.log(
    //   ScenarioCardOne!.current?.getBoundingClientRect()?.top,
    //   'ScenarioCardOne!.current?.getBoundingClientRect()?.top ',
    // )

    // console.log(
    //   ScenarioCardTwo!.current?.getBoundingClientRect()?.top,
    //   'ScenarioCardTwo!.current?.getBoundingClientRect()?.top ',
    // )
    // console.log(
    //   ScenarioCardThree!.current?.getBoundingClientRect()?.top,
    //   'ScenarioCardThree!.current?.getBoundingClientRect()?.top',
    // )

    // console.log(scroll?.top, 'scroll?.top')
  }, [scroll?.top])

  useEffect(() => {
    let intervalId
    let checkCount = 0 // 计数器
    const maxChecks = 10 // 最大检查次数

    const checkElementsVisibility = () => {
      const testAds = document.querySelectorAll('.checkImg')

      if (testAds.length === 0) {
        checkCount++
        if (checkCount >= maxChecks) {
          setAdBlocked(true)
          clearInterval(intervalId) // 超过最大检查次数后停止检查
        }
        return
      }

      testAds.forEach((testAd) => {
        if (testAd?.offsetHeight === 0 || testAd?.clientHeight === 0) {
          setAdBlocked(true)
        }
      })

      clearInterval(intervalId) // 检查完所有元素后停止检查
    }

    // 使用setInterval持续检查元素
    intervalId = setInterval(checkElementsVisibility, 200)

    return () => {
      clearInterval(intervalId)
    }
  }, [ScenarioCardOne.current])

  // 用于模拟广告屏蔽
  // useEffect(() => {
  //   setTimeout(function () {
  //     var elements = document.querySelectorAll('.checkImg')
  //     elements.forEach(function (element) {
  //       element.parentNode.removeChild(element)
  //     })
  //   }, 100)
  // }, [])

  return (
    <>
      <Seo
        title={adtrackYaml?.title}
        description={adtrackYaml?.desc}
        keywords={adtrackYaml?.keywords}
        saTitle={adtrackYaml?.saTitle}
      />
      <main>
        {adBlocked && (
          <div className={styles.adTip}>
            <div className={styles.content}>我们检测到您可能启用了广告拦截器，请关闭它以浏览页面完整信息。</div>
            <div className={styles.closeIcon} onClick={() => setAdBlocked(false)}>
              <img src={CloseIcon} alt="" />
            </div>
          </div>
        )}
        <Banner
          style={bannerBackgroundStyle}
          className={classnames('lg:pt-[160px] lg:pb-[60px]  bg-no-repeat bg-[#f9fafc]', styles.banner)}
          title={banner?.title}
          desc={banner?.desc}
          left_button_link={banner?.button_group?.left_button_link}
          left_button_text={banner?.button_group?.left_button_text}
          right_button_text={banner?.button_group?.right_button_text}
          right_button_link={banner?.button_group?.right_button_link}
          right_img={banner?.right_img?.publicURL || banner?.right_img}
          right_img_alt={banner?.right_img_alt}
          right_link={banner?.right_link}
          // showMask={false}
          curVideo={curVideo}
        />
        {/*  业务场景*/}
        <Section
          className="bg-[#F9FAFC] lg:bg-[#fff] pb-[40px] lg:pb-0 relative !pt-[20px] lg:!pt-0"
          title={scenario?.title}
          maskTitle={scenario?.mask_title}
        >
          <ScenarioCard
            ref={ScenarioCardOne}
            className="mt-[20px] lg:mt-[70px] relative z-[2]"
            dataSource={scenario?.dataSource[0]}
            isPc={!isMb}
            data={lottieDataOne}
          />
          <div className="h-auto w-[1200px] lg:block hidden mx-auto" ref={lottie1Warp}></div>
          <ScenarioCard
            ref={ScenarioCardTwo}
            className="mt-[20px] lg:mt-0 relative z-[2]"
            dataSource={scenario?.dataSource[1]}
            isPc={!isMb}
            data={lottieDataTwo}
          />
          <div className="h-auto w-[1200px] lg:block hidden mx-auto" ref={lottie2Warp}></div>
          <ScenarioCard
            ref={ScenarioCardThree}
            className="mt-[20px] lg:mt-0"
            dataSource={scenario?.dataSource[2]}
            isPc={!isMb}
            data={lottieDataThree}
          />
          <div className="h-auto w-[1200px] lg:block hidden mx-auto" ref={lottie3Warp}></div>
          <ScenarioCard
            ref={ScenarioCardFour}
            className="mt-[20px] lg:mt-0"
            dataSource={scenario?.dataSource[3]}
            isPc={!isMb}
            data={lottieDataFour}
          />
        </Section>
        {/* 实战案例 */}
        <Section
          className="lg:!pt-[40px] lg:pb-[120px] pb-[40px] !pt-[20px]"
          title={customer_story?.title}
          maskTitle={customer_story?.mask_title}
          style={customerStyle}
        >
          <SolutionsSwiper
            // caseMenuList={customer_story?.case_list}
            dataSource={customer_story?.data_source}
            hasTab={customer_story?.hasTab}
          />
        </Section>
        {/* 大咖分享 */}
        <Section className="bg-[#f9fafc] lg:bg-[#fff]" title={insights?.title} maskTitle={insights?.mask_title}>
          <CaseShare dataSource={insights} />
        </Section>
        {/* 更多通用解决方案 */}
        <Section className="bg-[#f9fafc] lg:bg-[#fff]" title={more?.title} maskTitle={more?.mask_title}>
          <SigningPlate className="lg:mt-[60px] lg:block" dataSource={more?.card_data} />
        </Section>
        <PageBottomCard
          className="lg:mt-[100px]"
          title="开启大数据分析与营销科技之旅！"
          desc="立即注册，和神策数据专家一起探讨数字化！"
          leftButtonText="体验 Demo"
          leftButtonHref="/demo/demo.html"
          rightButtonText="预约演示"
          rightButtonHref="/form/parade.html"
        />
        {/** 视频播放组件  暂时不需要 等到五月中旬视频出来了再放出来*/}
        <VideoPlayer
          videoOpt={{
            sources: 'https://ow-file.sensorsdata.cn/www/Video/%E5%B9%BF%E5%91%8A%E9%94%A40511.mov',
          }}
          fuRef={curVideo}
        />
      </main>
    </>
  )
}

export default Adtrack
